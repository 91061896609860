<template>
  <div>
    <label
      >{{ field.name }}
      <span class="text-danger" v-if="field.required">*</span>
      <span class="text-error" v-if="field.validation"
        >กรุณาเลือกอย่างน้อย 1 ข้อมูล</span
      ></label
    >
    <b-form-checkbox-group :name="`cb-${index}`">
      <b-form-checkbox
        v-for="(choice, i) in field.field_choices"
        :key="i"
        :value="choice.name"
        :disabled="statusCampaign != 0 && field.field_profile_type_id != 0"
        v-model="field.value"
        >{{ choice.name }}</b-form-checkbox
      >
    </b-form-checkbox-group>
  </div>
</template>

<script>
export default {
  name: "FieldCheckbox",
  props: {
    field: {
      required: true,
      type: Object,
    },
    statusCampaign: {
      required: true,
      type: Number,
    },
    index: {
      required: true,
      type: Number,
    },
  },
};
</script>

<style lang="scss" scoped>
.text-error {
  color: #ff0000;
  font-size: 14px !important;
  font-weight: normal !important;
}
</style>
